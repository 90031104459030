<template>
    <div class="contact__block">
        <h2 v-if="footer" class="heading --three --line --star">
            <span>{{ content.title }}</span>
            <Icon id="star" class="--star --30"/>
        </h2>
        <div class="contact">
            <a :href="content.address.link" target="_blank" class="contact__address">
                <span>{{ content.address.label }}</span>
                <Icon id="arrow"/>
            </a>
            <div class="contact__details">
                <a :href="`mailto:${content.email}`" class="contact__email" target="_blank">{{ content.email }}</a>
                <span>|</span>
                <a :href="phone" target="_blank">{{ content.phone }}</a>
            </div>
            <div class="contact__socials">
                <a :href="social[1]" v-for="(social, socialKey) in Object.entries(content.socials)" :key="socialKey" class="contact__socials-item" target="_blank">
                    <Icon :id="social[0]"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import {HasContentProp} from '@/mixins'
import {Icon} from '@/components/global'
export default {
    name: 'ContactDetails',
    mixins: [HasContentProp],
    props: {
        footer: {
            type: Boolean,
            default: true
        }
    },
    components: {
        Icon
    },
    computed: {
        phone() {
            return `tel:${this.content.phone.replaceAll('.', '')}`
        }
    }
}
</script>