<template>
    <Newsletter/>
    <footer data-section="contact">
        <div class="content --footer">
            <OpeningHours :content="$beet.options.footer.openingHours" v-reveal/>
            <OpeningHours :content="$beet.options.footer.kitchen" v-reveal="{delay: 200}"/>
            <ContactDetails :content="$beet.options.footer.contact" v-reveal="{delay: 250}"/>
        </div>
        <FooterCredits/>
    </footer>
</template>

<script>
import {ContactDetails, FooterCredits, Newsletter, OpeningHours} from '@/components/blocks'

export default {
    name: 'Footer',
    components: {ContactDetails, FooterCredits, Newsletter, OpeningHours}
}
</script>