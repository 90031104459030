<template>
    <Transition name="slide-down">
        <header v-if="!background || scrollingUp"
                :class="['navbar', {'--open': open}, {'--background': open || background}]">
            <nav class="navbar__top">
                <LangRouterLink :to="{name: 'home'}" class="navbar__logo">
                    <Image :source="$beet.options.navigation.main.logo"/>
                </LangRouterLink>
                <Transition name="fade-fast">
                    <div v-if="!mq.mdPlus" class="navbar__top-right">
                        <a :href="lastSecondaryLink.link" class="button --secondary" target="_blank">{{ lastSecondaryLink.label }}</a>
                        <HamburgerButton @click="open = !open" :open="open"/>
                    </div>
                </Transition>
                <Transition name="fade-fast">
                    <div v-if="mq.mdPlus" class="navbar__top-right heading">
                        <LangRouterLink :to="{name: 'home', hash: `#${$t(`anchors.${menuKey}`)}`}"
                                        v-for="(menuKey, menuKeyIndex) in $store.getters.menu" :key="menuKeyIndex"
                                        :class="['navbar__top-link', {'--active': currentSection  === menuKey}]">
                            {{ $beet.options.navigation.items[menuKey].label }}
                        </LangRouterLink>
                        <LangSwitchLink class="navbar__top-link"/>
                    </div>
                </Transition>
                <Icon id="star" class="--star --nav --30"/>
            </nav>
            <Transition name="fade-fast">
                <DropDown :is-open="open" content-class="navbar__bottom" v-if="!mq.mdPlus">
                    <div class="navbar__bottom-buttons">
                        <a :href="link.link" v-for="(link, linkKey) in secondaryLinks" :key="linkKey" class="button" target="_blank">
                            {{ link.label }}
                        </a>
                    </div>
                    <div class="navbar__bottom-links heading">
                        <LangRouterLink :to="{name: 'home', hash: `#${$t(`anchors.${menuKey}`)}`}"
                                        v-for="(menuKey, menuKeyIndex) in $store.getters.menu" :key="menuKeyIndex">
                            {{ $beet.options.navigation.items[menuKey].label }}
                        </LangRouterLink>
                        <LangSwitchLink/>
                    </div>
                    <ContactDetails :content="$beet.options.footer.contact" :footer="false"/>
                </DropDown>
            </Transition>
            <Transition name="fade-fast">
                <div v-if="mq.mdPlus" class="navbar__bottom-desktop">
                    <a :href="link.link"
                       :class="['button', {'--secondary': linkKey === $beet.options.navigation.secondary.items.length - 1}]"
                       v-for="(link, linkKey) in $beet.options.navigation.secondary.items" :key="`s${linkKey}`"
                       target="_blank">
                        {{ link.label }}
                    </a>
                </div>
            </Transition>
        </header>
    </Transition>
</template>

<script>
import {ContactDetails} from '@/components/blocks'
import {DropDown, Icon, Image, LangRouterLink} from '@/components/global'
import {HamburgerButton, LangSwitchLink} from '@/components/small'
import {CurrentSection} from '@/mixins'

export default {
    name: 'SiteNav',
    mixins: [CurrentSection],
    components: {ContactDetails, DropDown, HamburgerButton, LangSwitchLink, Image, Icon, LangRouterLink},
    inject: ['mq'],
    data() {
        return {
            background: false,
            lastScrollPosition: 0,
            open: false,
            scrollingUp: false
        }
    },
    computed: {
        lastSecondaryLink() {
            let links = this.$beet.options.navigation.secondary.items
            return links[links.length - 1]
        },
        secondaryLinks() {
            return this.$beet.options.navigation.secondary.items.slice(0, -1)
        }
    },
    watch: {
        '$route'() {
            if (this.open) this.open = false
        },
        'open'() {
            if (this.open && !document.body.classList.contains('--lock')) {
                document.body.classList.add('--lock')
            } else if (!this.open && document.body.classList.contains('--lock')) {
                document.body.classList.remove('--lock')
            }
        }
    },
    methods: {
        scrollWatchers() {
            this.background = window.scrollY > 20
            this.scrollingUp = this.open || this.lastScrollPosition > window.scrollY
            this.lastScrollPosition = window.scrollY
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollWatchers, {passive: true})
        window.addEventListener('scrollUpdated', () => {this.scrollingUp = true})
    }
}
</script>
