<template>
    <button :class="['navbar__hamburger', {'--open': open}]">
        <span v-for="key in 3" :key="key"></span>
    </button>
</template>

<script>
export default {
    name: 'HamburgerButton',
    props: {
        open: {
            type: Boolean,
            default: false
        }
    }
}
</script>