<template>
    <section class="section content --high">
        <h2 class="heading --two" v-reveal>{{ content.title }}</h2>
        <div class="star-border">
            <Icon id="star" class="--star --top-left --40" v-reveal="{delay: 400}"/>
            <div class="swipe-card__container" ref="container" v-reveal="{delay: 200}">
                <ul :class="['swipe-card__list', {'--reverse': parseInt(currentStep) === (content.items.length - 2)}]" ref="slider" @click="desktopScrollSlider(currentStep + 1)">
                  <SwipeCard :content="promotion" v-for="(promotion, promotionIndex) in content.items"
                               :key="promotionIndex"/>
                </ul>
            </div>
        </div>
    </section>
</template>
<script>
import {SwipeCard} from '@/components/cards'
import {Icon} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
    name: 'PromotionsSection',
    mixins: [HasContentProp],
    components: {Icon, SwipeCard},
}
</script>