<template>
    <a :href="content.menu.original" download target="_blank" class="text-center square-card">
        <div class="image__block">
            <Image :source="content.image" class="--square-card"/>
            <Icon id="roundArrow" class="square-card__icon"/>
        </div>
        <h3 class="square-card__title heading --card">{{ content.name }}</h3>
        <p>{{ content.note }}</p>
    </a>
</template>

<script>
import {Icon, Image} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
    name: 'MenuCard',
    mixins: [HasContentProp],
    components: {
        Icon,
        Image
    }
}
</script>

