<template>
    <div class="square-card text-center">
        <a :href="content.website" target="_blank" class="square-card__main">
            <Image :source="content.image" class="--square-card"/>
            <h3 class="square-card__title heading --card --link">
                <span>{{ content.name }}</span>
                <Icon id="arrow"/>
            </h3>
        </a>
        <a :href="content.address.link" target="_blank" class="square-card__address">
          <Icon id="marker"/>
          <span>{{ content.address.title }}</span>
        </a>
    </div>
</template>

<script>
import {Icon ,Image} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
    name: 'BusinessCard',
    mixins: [HasContentProp],
    components: {
        Icon,
        Image
    }
}
</script>

