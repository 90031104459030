<template>
    <section class="section --page-header">
        <div class="image__block --front">
            <Image :source="content.image" class="--top-line --left-line --page-header"/>
            <Icon id="star" class="--star --40 --top-left --page-header"/>
            <div class="content__block --padding --absolute --stacked">
                <h1 class="heading --section">{{ content.title }}</h1>
                <p>{{ content.description }}</p>
                <LangRouterLink :to="{name: 'home'}" class="button --top">
                    {{ content.button }}
                </LangRouterLink>
            </div>
        </div>
    </section>
</template>

<script>
import {Icon, Image, LangRouterLink} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
    name: 'PageHeader',
    mixins: [HasContentProp],
    components: {
        Icon,
        Image,
        LangRouterLink
    }
}
</script>


