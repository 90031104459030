<template>
  <TransitionGroup name="form-blocks" tag="div">
    <form v-if="step === 0" class="form" @submit.prevent="submit">
      <div class="form__input-container">
        <Transition name="label">
          <label v-if="!email.length" class="form__label" for="email">{{ $beet.options.footer.newsletter.label }}</label>
        </Transition>
        <input class="form__input" type="email" id="email" name="email" v-model="email">
      </div>
      <button :disabled="email === ''" type="submit" class="button">{{ $beet.options.footer.newsletter.button }}</button>
    </form>
    <div v-if="step === 1" class="form__processing">
      <div class="form__processing-loader"></div>
    </div>
    <div class="form" v-if="step === 2">
      <p class="form__success">{{ apiResponse }}</p>
      <button class="button" @click="resetForm">{{ $t('form.other') }}</button>
    </div>
  </TransitionGroup>
</template>

<script>
import http from '@/extensions/http'

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

export default {
  name: 'NewsletterForm',
  data() {
    return {
      apiResponse: '',
      email: '',
      step: 0
    }
  },
  methods: {
    callAPI() {
      this.step++
      http.post(`${process.env.VUE_APP_API_URL}/mailchimp?lang=${this.$i18n.locale}`, {
        email: this.email,
        language: this.$i18n.locale
      })
          .then(({data}) => {
            this.apiResponse = data
          })
          .catch(error => {
            this.apiResponse = error
          })
          .finally(() => {
            this.step ++
          })
    },
    resetForm() {
      this.email = ''
      this.step = 0
    },
    submit() {
      if(EMAIL_REGEX.test(this.email)) {
        this.callAPI()
      }
    }
  }
}
</script>

