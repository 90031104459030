export default {
    data() {
        return {
            currentSection: null,
            observer: null,
            prevYPosition: 0,
            scrollDown: true,
            sections: null
        }
    },
    methods: {
        getTargetSection(target) {
            if (!this.scrollDown) return target

            return target.nextElementSibling ?? target
        },
        observerCallback(entries) {
            entries.forEach(entry => {
                this.setScrollDirection()

                if (!this.shouldUpdate(entry)) return

                const target = this.getTargetSection(entry.target)
                this.updateCurrentSection(target)
            })
        },
        resetData() {
            this.currentSection = null
            this.observer = null
            this.prevYPosition = 0
            this.scrollDown = true
            this.sections = null
        },
        setScrollObserver() {
            this.sections = [...document.querySelectorAll('section[data-section]'), document.querySelector('footer')]
            if (!this.sections.length) window.setTimeout(this.setScrollObserver, 300)
            else {
                this.observer = new IntersectionObserver(this.observerCallback, {
                    rootMargin: '-160px',
                    threshold: 0
                })
                this.sections.forEach(section => this.observer.observe(section))
            }
        },
        setScrollDirection() {
            this.scrollDown = window.scrollY > this.prevYPosition
            this.prevYPosition = window.scrollY
        },
        shouldUpdate(entry) {
            return (this.scrollDown && !entry.isIntersecting) || (!this.scrollDown && entry.isIntersecting)
        },
        updateCurrentSection(target) {
            this.currentSection = target.dataset.section
        }
    },
    watch: {
        '$route.name': {
            handler() {
                this.resetData()
                window.setTimeout(this.setScrollObserver, 400)
            },
            immediate: true
        }
    }
}