<template>
    <section class="section --top">
        <div class="image__block">
            <Image :source="content.image" class="--header --bottom-line" v-reveal/>
            <Icon id="star" class="--star --60 --header" v-reveal="{delay: 200}"/>
            <Transition name="fade-fast">
                <WheelText v-if="mq.mdMinus" :text="content.wheel" class="--left --mobile" v-reveal="{delay: 300}"/>
            </Transition>
        </div>
        <div class="content__block content">
            <h1 class="heading --one text-center --header" v-reveal="{delay: 250}">{{ content.title }}</h1>
            <p class="content__block-text">
                <span v-reveal="{delay: 250}">{{ content.description }}</span>
                <Transition name="fade-fast">
                    <div v-if="mq.lgPlus" class="wheel__container">
                        <WheelText :text="content.wheel" class="--relative" v-reveal="{delay: 300}"/>
                    </div>
                </Transition>
            </p>
        </div>
    </section>
</template>

<script>
import {Icon, Image} from '@/components/global'
import {WheelText} from '@/components/small'
import {HasContentProp} from '@/mixins'

export default {
    name: 'HomeHeader',
    mixins: [HasContentProp],
    inject: ['mq'],
    components: {
        Icon,
        Image,
        WheelText
    }
}
</script>
