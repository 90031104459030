import { createStore } from 'vuex'

export default createStore({
  state: {
    menu: ['menus', 'promotions', 'businesses', 'careers', 'contact']
  },
  getters: {
    menu: state => state.menu
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
