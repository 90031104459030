<template>
  <RouterLink :to="to">
    {{ mq.mdPlus ? this.toLang : $t(`langs.${toLang}`) }}
  </RouterLink>
</template>

<script>
export default {
  name: 'LangSwitchLink',
  inject: ['mq'],
  computed: {
    to() {
      return {
        name: this.toLang !== this.$i18n.fallbackLocale
            ? `${this.$route.name}:${this.toLang}`
            : this.$route.name.substr(0, this.$route.name.indexOf(':')),
        params: this.$route.params,
        query: this.$route.query
      }
    },
    toLang() {
      return this.$i18n.availableLocales.filter(lang => lang !== this.$i18n.locale)[0]
    }
  }
}
</script>