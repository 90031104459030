<template>
    <div class="content" :id="$t('anchors.contact')">
        <TitleBlock :content="titleBlock"/>
        <NewsletterForm v-reveal="{delay: 250}"/>
    </div>
</template>

<script>
import {NewsletterForm, TitleBlock} from '@/components/blocks'

export default {
    name: 'Newsletter',
    components: {
        NewsletterForm,
        TitleBlock
    },
    computed: {
        titleBlock() {
            return {
                description: this.$beet.options.footer.newsletter.description,
                title: this.$beet.options.footer.newsletter.title
            }
        }
    }
}
</script>

