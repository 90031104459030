<template>
    <section class="section">
        <div class="image__block">
            <Image :source="content.image" :class="['--bottom-line', '--section', {'--left-line': !right, '--right-line': right}]" v-reveal/>
            <WheelText :text="content.wheel" :class="{'--left': right, '--right': !right}" v-reveal="{delay: 200}"/>
            <Icon id="star" :class="['--star', '--40', {'--left': !right}, {'--right': right}]" v-reveal="{delay: 500}"/>
        </div>
        <div :class="['content__block', '--padding', '--stacked', {'text-right': right}]">
            <h2 class="heading --section --top" v-reveal="{delay: 250}">{{ content.title }}</h2>
            <p class="content__block-text" v-reveal="{delay: 300}">{{ content.description }}</p>
        </div>
    </section>
</template>

<script>
import {Icon, Image} from '@/components/global'
import {WheelText} from '@/components/small'
import {HasContentProp} from '@/mixins'

export default {
    name: 'RepeatableSection',
    mixins: [HasContentProp],
    components: {
        Icon,
        Image,
        WheelText
    },
    computed: {
      right() {
        return this.content.isRight ?? true
      }
    }
}
</script>