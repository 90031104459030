export default {
    anchors: {
        businesses: 'restos',
        careers: 'carrieres',
        contact: 'contact',
        menus: 'menus',
        promotions: 'promotions'
    },
    beet: 'Site conçu et développé par',
    form: {
        other: 'Soumettre un autre courriel'
    },
    langs: {
        en: 'English',
        fr: 'Français'
    }
}
