import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        paths: {en: '/'},
        name: 'home',
        component: () => import('@/views/Home')
    },
    {
        path: '/404',
        paths: {en: '/404'},
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const scrollUpdated = new Event('scrollUpdated')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes,
    scrollBehavior(to) {
        setTimeout(() => {
            window.dispatchEvent(scrollUpdated)
        }, 800)
        if(to.hash) {
            return {
                behavior: 'smooth',
                el: to.hash,
                top: window.innerWidth < 1025 ? 120 : 155
            }
        }
        return {behavior: 'smooth', top: 0}
    }
})

export default router
