<template>
    <div class="loader__container" id="loader">
        <Icon id="star" class="loader"/>
    </div>
</template>

<script>
import {Icon} from '@/components/global'

export default {
    name: 'Loader',
    components: {
        Icon
    }
}
</script>
