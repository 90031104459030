<template>
  <div class="credits" v-reveal>
    <p>{{ `${$beet.options.footer.credits} ${new Date().getFullYear()}` }} </p>
    <p>|</p>
    <a v-if="$beet.options.footer?.privacy?.link" :href="$beet.options.footer.privacy.link" target="_blank" rel="nofollow">
      {{ $beet.options.footer?.privacy?.label }}
    </a>
    <p>|</p>
    <p>
      <span>{{ `${$t('beet')} ` }}</span>
      <a href="https://boitebeet.com" target="_blank" class="credits__link">Beet</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FooterCredits'
}
</script>

