<template>
    <div class="text-center">
        <h2 v-if="content.title" class="heading --two" v-reveal>{{ content.title }}</h2>
        <p v-if="content.description" class="content__description" v-reveal="{delay: 200}">{{ content.description }}</p>
    </div>
</template>

<script>
import {HasContentProp} from '@/mixins'

export default {
    name: 'TitleBlock',
    mixins: [HasContentProp]
}
</script>

