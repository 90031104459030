<template>
    <div class="contact__block">
        <h2 class="heading --three --line">{{ content.title }}</h2>
        <ul class="schedule__list">
            <li v-for="(schedule, scheduleKey) in content.items" :key="scheduleKey" class="schedule">
                <p>{{ schedule.title }}</p>
                <p>{{ schedule.description }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'OpeningHours',
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>