<template>
    <svg viewBox="0 0 100 100" class="wheel">
        <defs>
            <path id="circle" d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0"/>
        </defs>
        <text>
            <textPath xlink:href="#circle" class="wheel__text" id="text">
                {{ text }}
            </textPath>
        </text>
        <path d="M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0" class="wheel__line" stroke-width="1" stroke-dasharray="300" stroke-dashoffset="276" />
    </svg>
</template>

<script>
export default {
    name: 'WheelText',
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>


