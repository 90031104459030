<template>
  <section class="section content">
    <TitleBlock :content="content"/>
    <div class="square-card__list star-border">
      <Icon id="star" class="--star --45 --square-card"/>
      <Component :is="component" v-for="(card, cardIndex) in sortedItems(cards)" :content="card" :key="cardIndex"
                 :class="{'--blur': hover !== null && hover !== cardIndex}" @mouseenter="hover = cardIndex"
                 @mouseleave="hover = null" v-reveal="{delay: 200 + (50 * cardIndex)}"/>
    </div>
  </section>
</template>

<script>
import {TitleBlock} from '@/components/blocks'
import {BusinessCard, MenuCard} from '@/components/cards'
import {Icon} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
  name: 'MenusSection',
  mixins: [HasContentProp],
  components: {BusinessCard, Icon, MenuCard, TitleBlock},
  props: {
    component: {
      type: String,
      default: 'MenuCard',
      validator: (value) => ['BusinessCard', 'MenuCard'].includes(value)
    }
  },
  data() {
    return {
      hover: null
    }
  },
  computed: {
    cards() {
      switch (this.component) {
        case 'BusinessCard':
          return this.$beet.businesses
        default:
          return this.$beet.menus
      }
    }
  },
  methods: {
    sortedItems(items) {
      return Object.values(items).sort((itemA, itemB) => itemA.order - itemB.order)
    }
  }
}
</script>


