import './startup'

import {createApp} from 'vue'
import {Vue3Mq} from "vue3-mq";
import mitt from 'mitt';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'
import langRouter from '@/extensions/langRouter'
import reveal from '@/extensions/reveal'

import '@/styles/app.scss'
/* --- GOOGLE ANALYTICS --- */
import VueGtag from "vue-gtag";

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(langRouter, 'fr')
    .use(GlobalComponents)
    .use(BeetAPI, {store, apiUrl: process.env.VUE_APP_API_URL})
    .use(Vue3Mq, {
        breakpoints: {
            xs: 0,
            sm: 566,
            md: 1024,
            lg: 1300,
            xl: 1440
        }
    })
    .use(reveal)

const emitter = mitt();
app.provide('emitter', emitter)

app.use(VueGtag, {bootstrap: false}, router);


/* --- GOOGLE RECAPTCHA ---
   import {VueReCaptcha} from "vue-recaptcha-v3";
   app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

 */

app.mount('#app')
