export default {
    anchors: {
        businesses: 'restaurants',
        careers: 'careers',
        contact: 'contact',
        menus: 'menus',
        promotions: 'promotions'
    },
    beet: 'Site designed and developed by',
    form: {
        other: 'Submit an other email'
    },
    langs: {
        en: 'English',
        fr: 'Français'
    }
}
