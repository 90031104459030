<template>
    <section class="section --overlap">
        <TitleBlock :content="content"/>
        <a :href="content.link" target="_blank" class="button --center" v-reveal="{delay: 250}">{{ content.button }}</a>
    </section>
</template>

<script>
import {TitleBlock} from '@/components/blocks'
import {HasContentProp} from '@/mixins'

export default {
    name: 'SmallCTASection',
    mixins: [HasContentProp],
    components: {TitleBlock}
}
</script>

