<template>
    <li class="swipe-card">
        <Image :source="content.image" class="--promotion"/>
        <h3 class="heading --card swipe-card__content">{{ content.title }}</h3>
        <RawHtml :html="content.description" class="swipe-card__content"/>
    </li>
</template>

<script>
import {Image, RawHtml} from '@/components/global'
import {HasContentProp} from '@/mixins'

export default {
    name: 'PromotionCard',
    mixins: [HasContentProp],
    components: {
        Image,
        RawHtml
    }
}
</script>